html, body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.loader-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid !important;
    place-items: center;
}
.loader {
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.app-wrapper {
  flex: 1;

  > div {
    height: 100%;
  }
}
.errorCloseBtn {
    right:"10px";
    position:"absolute";
   }
.App {
  text-align: center;
  height: 100%;
  .arrow { 
    background: #326598;
    // background: #5b83ac;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: 50px 20px;
    left: 0px;
    top: -45px;
    width: 16px;
    height: 16px; 
    position: absolute;
  }

  

  .arrow:after {
    content: '';
    width: 16px;
    height: 16px;
    background: white;
    position: absolute;
    left: 3px;
    top: 3px;
    // transform: translateY(-100%);
  
  }

  
  &.hide-header {
    .arrow { 
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      margin: 45px 20px;

    }

    iframe {
        overscroll-behavior: contain;
      &#header {
        display: none;
      }

      &.content {
        height: 100%;
        top: 0;
      }
    }
  }
}



.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #326598;
}

.lightbox-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 499;
}

.close-lightbox {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.pdf {
        right: 0;
    }
}

.structure-element {
  position: absolute;
}

.swipeable-container {
    .structure-element {
        position: inherit;
        height: 100%;
        display: block;
    }
    .react-swipeable-view-container {
        height: 100%;
    }
}

.lightbox-frame-wrapper {
    position: absolute;
    z-index: 500;
    // border: 1px solid #000;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 5px;
    background: #fff;
    max-width:100%;
    max-height: 100%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: hidden;

    &.pdf {
        background: none;
        box-shadow: none;
    }
}

iframe {
  width: 100%;
  border: none;
  height: 100%;
  overscroll-behavior: contain; 
  
  &#header {
    height: 50px;
  }

  &.content {
    height: calc(100% - 50px);
    top: 50px;
  }

  &.login {
    height: 100%;
  }

  &.pdf {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 92%;
        max-width: 100%;
    }
}

.hidden-lightbox {
    height: 0 !important;
    width: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 500px) {
  iframe {
    overscroll-behavior: contain;
    &.lightbox {
      border: none;
      transform: none;
      top: 0;
      left: 0;
    }
  }
}